import { VideoTrack } from 'twilio-video'

export enum ConsultationStatus {
  Initiated = 'initiated',
  Booked = 'booked',
  Claimed = 'claimed',
  Serving = 'serving',
  Payed = 'payed',
  Refunded = 'refunded',
  Ended = 'completed',
  NoShow = 'no-show',
  Cancelled = 'cancelled',
}

export enum PaymentStatus {
  Initiated = 'initiated',
  Authorized = 'authorized',
  Verified = 'verified',
  Captured = 'captured',
  Refunded = 'refunded',
  Failed = 'failed',
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  email: string | null
  createdAt: string
  phone: string | null
  countryCode: string | null
  ssn?: string | null
  nin?: string | null
  birthdate: string | null
  name: string | null
}

export interface IPartner {
  id: string
  name: string
  logoUrl?: string // url
  email?: string // email
  orgNr?: string
  address?: string
  onboardingType?: string // defaults to 'default'
}
export interface IMembershipCampaign {
  id: string // uuid
  partnerId: string // uuid
  membershipCode: string
  description?: string
  start: Date | null
  end: Date | null
  title: string
}
export interface IPartnerMembership {
  id: string // uuid
  userId: string // uuid
  membershipCode: string
  type?: string

  partnerId: string // uuid
  partner?: IPartner

  membershipCampaignsId: string // uuid
  membershipCampaign?: IMembershipCampaign

  custom: string | Record<string, any> | null

  createdAt: Date
}

export interface IPartnerTransaction {
  id: string // uuid
  userId: string // uuid
  paymentId: string // uuid
  partnerMembershipId: string | null // uuid
  productId: string // Product id
  partnerId: string // uuid
  type: string // 'rebate'
  amount: number
  createdAt: string
  productCampaignId: string // uuid
  appointmentId: string // uuid
  status: string // 'completed'
  partnerMembership?: IPartnerMembership
  insuranceCountry?: string
}

export interface IPayment {
  id: string // uuid
  appointmentId: string // uuid
  amount: number
  currency: string // 'NOK'
  authorizationUrl: string | null
  status: PaymentStatus
  createdAt: string
  paymentCardId: string | null
  userId: string
}

export interface IConsultation {
  id: string // uuid
  customerId: string // uuid
  customer?: IUser
  customerChildId?: string // uuid
  customerChild?: {
    id: string
    parentId: string
    name: string
    ssn: string
  }
  professionalId: string | null // uuid
  professional?: IUser
  resourceId: string | null // waitwhile booking id

  practitionerId?: string // is it a one on one?

  productId: string // Product ids
  clinicId?: string // clinic short id
  status: ConsultationStatus

  start: string
  createdAt: string
  startedAt: string | null
  endedAt: string | null
  cancelledAt: string | null
  refundedAt: string | null

  partnerTransaction?: IPartnerTransaction
  payment?: IPayment

  notes: string
}

export type TConsultations = IConsultation[]

type TVideoTrackStats = {
  dimensions: VideoTrack.Dimensions | null
  frameRate: number | null
  packetsLost: number | null
}

export interface IConsultationStats {
  localVideoStats: TVideoTrackStats
  remoteVideoStats: TVideoTrackStats
}
