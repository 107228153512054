import { useIDToken } from '@hooks/useSession'
import config from '@lib/config'
import {
  ConsultationStatus,
  IConsultation,
  TConsultations,
} from '@lib/types/consultations'
import { isDev } from '@utils/common'
import { getInstance } from 'api'
import { addHours, subDays, startOfDay, roundToNearestMinutes } from 'date-fns'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { logsink } from '@lib/logsink'

const fetchConsultation = async (id: string, jwt: string) => {
  const consultation = await getInstance({ jwt })
    .get(`${config.coreApi}/v1/appointments/${id}`)
    .then((res) => res.data);
  if (!consultation.customerChildId) {
    return consultation
  }
  try {
    const customerChild = await getInstance({ jwt })
      .get(`/v1/appointments/${id}/child`)
      .then(res => res.data);
    return { ...consultation, customerChild }
  } catch (err: unknown) {
    const error = err as Error
    logsink.error({}, error.message)
  }
  return consultation
}

export const useConsultationQuery = (id: string) => {
  const idToken = useIDToken()
  return useQuery<IConsultation, Error>(
    ['consultation', id],
    () => fetchConsultation(id, idToken),
    { enabled: !!idToken, refetchOnWindowFocus: !isDev },
  )
}
interface IConsultationsQueryProps {
  productId?: string // api product ids
  preferredRole?: string // api user roles
  from?: string // any parsable date string
  to?: string // any parsable date string
  statuses?: ConsultationStatus[]
}

const fetchConsultationHistory = async (
  jwt: string,
  params: {
    days: number
  },
) => {
  const start = new Date()
  logsink.info({}, `fetch consultation history for ${params.days} days`)
  const consultations = await getInstance({ jwt })
    .get(`${config.coreApi}/v1/appointments/video-history/${params.days}`)
    .then((res) => res.data)
  const end = new Date()
  logsink.info(
    {
      consultationsLength: consultations.length,
      duration: `${end.getTime() - start.getTime()}ms`,
    },
    'done fetching consultations',
  )
  return consultations
}

const fetchConsultations = async (
  jwt: string,
  params: IConsultationsQueryProps,
) => {
  const start = new Date()
  logsink.info({ params }, 'fetch consultations')
  const consultations = await getInstance({ jwt })
    .get(`${config.coreApi}/v1/appointments`, {
      params,
      paramsSerializer: (params) => {
        const search = new URLSearchParams()
        Object.entries(params).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            search.append(key, value.join(','))
          } else {
            search.append(key, value)
          }
        })
        return search.toString()
      },
    })
    .then((res) => res.data)
  const end = new Date()
  logsink.info(
    {
      consultationsLength: consultations.length,
      duration: `${end.getTime() - start.getTime()}ms`,
    },
    'done fetching consultations',
  )
  return consultations
}

/**
 * fetchMyConsultations - fetches current auth user's future consultations
 */
export const fetchMyConsultations = async (jwt: string) => {
  const response = await getInstance({ jwt }).get(`${config.coreApi}/v1/appointments/practitioner/future`)
  const appointments = response.data;
  logsink.info({
    appointmentsLength: appointments.length
  }, 'done fetching practitioners future appointments')
  return appointments
}


/**
 * useMyConsultationsQuery - useQuery wrapper for 'my appointments'
 * @returns appointments
 */
export const useMyConsultationsQuery = ({
  refetchInterval
}: { refetchInterval: number }) => {
  const idToken = useIDToken()
  return useQuery<TConsultations, Error>(
    ['my-consultations'],
    () => fetchMyConsultations(idToken),
    {
      enabled: !!idToken,
      staleTime: 60 * 1000,
      refetchOnWindowFocus: !isDev,
      refetchInterval
    },
  )
}

export const useConsultationsQuery = (props: IConsultationsQueryProps = {}) => {
  const idToken = useIDToken()
  return useQuery<TConsultations, Error>(
    ['consultations', { statuses: props.statuses }],
    () => fetchConsultations(idToken, props),
    { enabled: !!idToken, staleTime: 60 * 1000, refetchOnWindowFocus: !isDev },
  )
}

export const useConsultationHistoryQuery = (props: { days: number }) => {
  const idToken = useIDToken()
  return useQuery<TConsultations, Error>(
    ['consultationHistory', { days: props.days }],
    () => fetchConsultationHistory(idToken, props),
    { enabled: !!idToken, staleTime: 60 * 1000, refetchOnWindowFocus: !isDev },
  )
}

// Default hook for fetching consultations
export const useConsultations = (statuses: ConsultationStatus[]) => {
  const dates = {
    from: startOfDay(
      subDays(new Date(), 2), // start of day, 2 days ago
    ).toISOString(),
    to: roundToNearestMinutes(
      addHours(new Date(), 3), // add hours as param to change
      { nearestTo: 10 },
    ).toISOString(),
  }
  logsink.info({ dates, statuses }, 'useConsultations hook')

  const { data, ...rest } = useConsultationsQuery({
    ...dates,
    statuses,
  })

  return {
    ...rest,
    data: data?.filter((consultation) =>
      statuses.includes(consultation.status),
    ),
  }
}

/**
 * useMyConsultations - get the current practitioner's future consultations
 */
export const useMyConsultations = () => {
  return useMyConsultationsQuery({
    refetchInterval: 1000 * 10 // 10s
  })
};

/**
 * useConsultationHistory - hook for fetching consultation history of a practitioner
 * @param days
 */
export const useConsultationHistory = (days: number) =>
  useConsultationHistoryQuery({ days })

// Claim a prescription
const claimConsultation = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.coreApi}/v1/appointments/${id}?action=claim`)
    .then((res) => res.data.req)
}

export const useClaimConsultation = (
  onSuccess?: (id: string) => void,
  onError?:
    | ((
      error: Error,
      variables: string,
      context: unknown,
    ) => void | Promise<unknown>)
    | undefined,
) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => claimConsultation(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['consultations'])
      await queryClient.invalidateQueries(['consultation', variables])
      onSuccess?.(variables)
    },
    onError,
  })
}

// Unclaim a prescription
const unclaimConsultation = (id: string, jwt: string) => {
  return getInstance({ jwt })
    .put(`${config.coreApi}/v1/appointments/${id}?action=unclaim`)
    .then((res) => res.data.req)
}

export const useUnclaimConsultation = (onSuccess?: (id: string) => void) => {
  const idToken = useIDToken()
  const queryClient = useQueryClient()
  return useMutation((id: string) => unclaimConsultation(id, idToken), {
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['consultations'])
      await queryClient.invalidateQueries(['consultation', variables])
      onSuccess?.(variables)
    },
  })
}
